import { useState, useEffect } from "react";
/**
 * @hook
 * @name usePagination
 * @author Iris Succi
 * @date 2023
 * @description Un hook qui permet de paginer les données des tableaux.
 */
export const usePagination = (data: any, itemsPerPage: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const totalPageCount = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Triez les données avant de les paginer
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.ANA_DATEREALISATION).getTime();
    const dateB = new Date(b.ANA_DATEREALISATION).getTime();
    return dateB - dateA; // Triez du plus récent au plus ancien
  });

  useEffect(() => {
    setFilteredData(sortedData.slice(indexOfFirstItem, indexOfLastItem));
  }, [data, indexOfFirstItem, indexOfLastItem]);

  return {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    setFilteredData,
    totalPageCount,
    indexOfLastItem,
    indexOfFirstItem,
  };
};
