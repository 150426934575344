/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

/**
 * @hook
 * @name useOutsideClick
 * @author Iris Succi
 * @date 2023
 * @description Un hook qui permet de gérer les événements de clic en dehors d'un élément.
 */

const useOutsideClick = (initialState: boolean, onOutsideClick: () => void) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export default useOutsideClick;
