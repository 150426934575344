import { useState } from "react";
import French from "../../assets/icons/french.png";
import English from "../../assets/icons/english.png";
import { useCurrentLangContext } from "../../context/LangContext";
/**
 * @component
 * @name SelectLang
 * @description Un composant qui permet de sélectionner la langue de l'application. 
 * @author Iris Succi
 * @date 2023
 */
const SelectLang = () => {
  const [open, setOpen] = useState(false);
  const { lang, toggleLang } = useCurrentLangContext();
  return (
    <div className="w-18 font-medium h-auto flex flex-col relative ">
      <div
        onClick={() => setOpen(!open)}
        className={`bg-white w-full p-1 flex items-center justify-between`}
      >
        {lang === "FR" ? (
          <img
            src={French}
            alt="Drapeau francais"
            className="w-8 cursor-pointer"
          />
        ) : (
          <img
            src={English}
            alt="Drapeau anglais"
            className="w-8 cursor-pointer"
          />
        )}
      </div>
      <ul
        className={`bg-white mt-2 overflow-y-auto absolute ${
          open ? "max-h-32 w-18 z-50 top-[30px] left-1" : "max-h-0"
        } `}
      >
        {lang === "FR" ? (
          <button
            onClick={() => {
              toggleLang("EN");
              setOpen(false);
            }}
          >
            <img src={English} alt="Drapeau anglais" className="w-8" />
          </button>
        ) : (
          <button
            onClick={() => {
              toggleLang("FR");
              setOpen(false);
            }}
          >
            <img src={French} alt="Drapeau francais" className="w-8" />
          </button>
        )}
      </ul>
    </div>
  );
};

export default SelectLang;
