import { useEffect, useRef, useState } from "react";
import RenderClientFile from "./RenderClientFile";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { useLoadingStore } from "../../../../store/utils/loading";
import { useClientFileStore } from "../../../../store/Crm/ClientFile";
import {
  getImplicitGroups,
  getPersonnesFile,
  getVarFileDossier,
  patchPersonnesFile,
} from "../../../../utils/customerFile";
import {
  errorFetch,
  errorSendNewTime,
  successUpdateDossier,
} from "../../../../utils/toaster";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import { getVarFiche } from "../../../../utils/folders";
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";
import { getParticipantsFields } from "../../../../utils/participants";

/**
 * @component
 * @name UpdateClientFile
 * @author Succi Iris
 * @date 2024
 * @description UpdateClientFile est un composant qui permet de mettre à jour un dossier client
 */
const UpdateClientFile = () => {
  const { ficRef, setFicRef, PENT_ID, PDOS_ID, DCLE, setDCLE } = useExplorer();
  const { isLoading, setIsLoading } = useLoadingStore();
  const { setChoiceTab } = useUtilsCrmStore();
  const {
    selectedGroup,
    setSelectedGroup,
    selectedValues,
    setSelectedValues,
    inputValues,
    setInputValues,
    setLocalCity,
    setLocalCp,
    setFields,
    data,
    setData,
    setImplicitesGroupes,
    setVarFilesDossier,
    setVarFiches,
  } = useClientFileStore();

  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (scrollableDivRef?.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
    // Récupère les types de champs
    getParticipantsFields(1, PENT_ID, PDOS_ID)
      .then((response) => {
        setFields(response.data);
      })
      .catch((err) => {
        errorFetch();
        console.log(err);
      });

    // Récupère les variables de dossier
    getVarFileDossier()
      .then((response) => {
        setVarFilesDossier(response.data);
      })
      .catch((err) => {
        errorFetch();
        console.log(err);
      });

    // Récupère les groupes implicites
    getImplicitGroups()
      .then((response) => {
        const formattedData = response.data.map((group: any) => ({
          value: group.DOS_CODETRAVAIL + " - " + group.DOS_LIBTRAVAIL,
          label: group.DOS_LIBTRAVAIL,
        }));
        setImplicitesGroupes(formattedData);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });

    if (PENT_ID !== 0) {
      getVarFiche(PDOS_ID, PENT_ID)
        .then((response) => {
          setVarFiches(response.data);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err);
        });
    }
  }, [ficRef, update]);

  useEffect(() => {
    if (ficRef === 0) {
      return;
    } else {
      setIsLoading(true);
      // Recupere les donnees du dossier client
      getPersonnesFile(ficRef)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
          setLocalCity(res.data["Adresse principale (Ville)"]);
          setLocalCp(res.data["Adresse principale (CP)"]);
          setDCLE(res.data.DCLE);

          // Initialisation du groupe implicite
          setSelectedGroup(
            res.data.DOS_CODETRAVAIL + " - " + res.data.DOS_LIBTRAVAIL
          );

          // Initialisation des variables de dossier
          res.data.DOS_VARS.forEach((variable: any) => {
            setSelectedValues(
              variable.PVD_ID,
              variable.PVDL_CODE + ";" + variable.PVDL_LIBELLE
            );
          });

          // Initialisation des variables de fiche
          res.data.FIC_VARS.forEach((fiche: any) => {
            setInputValues(fiche.id, fiche.value);
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [ficRef, update]);

  /**
 * @function
 * @name handleUpdate
 * @author Succi Iris
 * @date 2024
 * @description handleUpdate est une fonction qui permet de mettre à jour un dossier client
 */
  const handleUpdate = () => {
    const { DOS_CODE, PDOS_ID, PENT_ID, FIC_VARS, DOS_VARS, ...dataToUpdate } =
      data;

    setIsLoading(true);
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    if (selectedGroup !== "") {
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");
    }

    const dataToSend = {
      ...dataToUpdate,
      DOS_VARCODE: selectedValues,
      FIC_VAR: inputValues,
      DOS_CODETRAVAIL,
      DOS_LIBTRAVAIL,
    };

    patchPersonnesFile(DCLE, dataToSend)
      .then(() => {
        setIsLoading(false);
        successUpdateDossier();
        setDCLE(0);
        setData({});
        setLocalCp("");
        setLocalCity("");
        setImplicitesGroupes([]);
        setVarFilesDossier([]);
        setSelectedGroup("");
        setUpdate(!update);
      })
      .catch((error) => {
        errorSendNewTime();
        setIsLoading(false);
        console.log(error);
      });
  };

  return !isLoading ? (
    <div
      ref={scrollableDivRef}
      className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-t-none rounded-b-md overflow-y-scroll text-sm"
    >
      <h2 className="my-5 text-xl font-bold">Dossier client</h2>
      <div className="flex flex-col justify-start items-start w-full">
        {/* Affiche tous les inputs */}
        <RenderClientFile />
        <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
          <div className="col-start-4 col-end-5 w-2/3 mb-5">
            <button
              className="w-full mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => {
                setData({});
                setLocalCp("");
                setLocalCity("");
                setImplicitesGroupes([]);
                setVarFilesDossier([]);
                setSelectedGroup("");
                setChoiceTab("");
                setFicRef(0);
              }}
            >
              Fermer
            </button>
          </div>
          <div className="col-start-5 col-end-6 w-2/3 mb-5">
            <button
              className="w-full mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => handleUpdate()}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
      <CircleLoarder />
    </div>
  );
};

export default UpdateClientFile;
