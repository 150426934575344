/**
 * @function decrementDate
 * @param {Date} startDate - The start date
 * @param {(date: Date) => void} setStartDate - Function to set the start date
 * @description Cette fonction décrémente la date de début de 7 jours et définit cette nouvelle date comme date de début. Si aucune date de début n'est fournie, aucune action n'est effectuée.
 * @author IRIS SUCCI
 * @since 2023-06-30  
*/
export const decrementDate = (startDate : Date, setStartDate: (date: Date ) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() - 7);
      setStartDate(newDate);
    }
  };

  /**
 * @function decrementDateByDay
 * @param {Date} startDate - The start date
 * @param {(date: Date) => void} setStartDate - Function to set the start date
 * @description Cette fonction décrémente la date de début de 1 jour et définit cette nouvelle date comme date de début. Si aucune date de début n'est fournie, aucune action n'est effectuée.
 * @author IRIS SUCCI
 * @since 2023-06-30  
*/
export const decrementDateByDay = (startDate : Date, setStartDate: (date: Date) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() - 1);
      setStartDate(newDate);
    }
  };

/**
 * @function incrementDate
 * @param {Date } startDate - The start date
 * @param {(date: Date) => void} setStartDate - Function to set the start date
 * @description Cette fonction incrémente la date de début de 7 jours et définit cette nouvelle date comme date de début. Si aucune date de début n'est fournie, aucune action n'est effectuée.
 * @author IRIS SUCCI
 * @since 2023-06-30  
 */
export const incrementDate = (startDate : Date , setStartDate : (date: Date ) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + 7);
      setStartDate(newDate);
    }
  };
  
/**
 * @function incrementDateByDay
 * @param {Date } startDate - The start date
 * @param {(date: Date) => void} setStartDate - Function to set the start date
 * @description Cette fonction incrémente la date de début de 1 jour et définit cette nouvelle date comme date de début. Si aucune date de début n'est fournie, aucune action n'est effectuée.
 * @author IRIS SUCCI
 * @since 2023-06-30  
 */
export const incrementDateByDay = (startDate : Date, setStartDate : (date: Date ) => void) => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + 1);
      setStartDate(newDate);
    }
  };


/**
 * @function getFormattedDate
 * @returns {string} The current date formatted as DD/MM/YYYY
 * @description Cette fonction retourne la date actuelle formatée en DD/MM/YYYY. Par exemple, si la date actuelle est le 30 juin 2023, la fonction retournera "30/06/2023".
 * @author IRIS SUCCI
 * @since 2023-06-30  
 */
export const getFormattedDate = () => {
  const date = new Date();
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
};

/**
 * @function getFormattedDateEN2
 * @returns {string} The current date formatted as DD/MM/YYYY
 * @description Cette fonction retourne la date actuelle formatée en MM/DD/YYYY. Par exemple, si la date actuelle est le 30 juin 2023, la fonction retournera "06/30/2023".
 * @author IRIS SUCCI
 * @since 2023-06-30  
 */
export const getFormattedDateEN2 = (dateStr: string) => {
  const date = new Date(dateStr);

  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
};
