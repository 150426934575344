import { useEffect } from "react";
import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";
import { getDocumentsByDos } from "../../../utils/customerFile";
import { useExplorer } from "../../../store/Crm/Explorer";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useLoadingStore } from "../../../store/utils/loading";
import {
  dateFormattedFr,
  delphi2html_color,
} from "../../../utils/TimeManager/timeUtils";
import plane from "../../../assets/icons/plane.png";
import eye from "../../../assets/icons/eye.svg";
import { downloadDocument } from "../../../utils/documents";

/**
 * @component
 * @name DocumentsList
 * @author Succi Iris
 * @date 2024
 * @description DocumentsList est un composant qui permet de lister les documents
 */
const DocumentsList = () => {
  // Store
  const { documentsList, setDocumentsList } = useUtilsCrmStore();
  const { dosRef } = useExplorer();
  const { isLoading } = useLoadingStore();

  useEffect(() => {
    getDocumentsByDos(dosRef)
      .then((response) => {
        const documentsSorted = response.data.sort(
          (a: any, b: any) =>
            new Date(b.DOC_DATE).getTime() - new Date(a.DOC_DATE).getTime()
        );
        setDocumentsList(documentsSorted);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  /**
 * @function
 * @name handleDownload
 * @author Succi Iris
 * @date 2024
 * @description handleDownload est une fonction qui permet de télécharger un document
 */
  const handleDownload = (doc_ref: number) => {
    downloadDocument(doc_ref)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error(error);
      });
  };

/**
 * @function
 * @name handleDeleteDocument
 * @author Succi Iris
 * @date 2024
 * @description handleDeleteDocument est une fonction qui permet de supprimer un document
 */
/*   const handleDeleteDocument = (doc_ref: number) => {
    deleteDocument(doc_ref)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }; */

  return (
    <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
          <CircleLoarder />
        </div>
      ) : (
        <div className="w-full h-5/6 flex flex-col justify-start items-center border-[1px] text-sm overflow-y-scroll">
          <table className="w-full h-5/6  ">
            <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0 rounded-sm">
              <tr className="flex w-full font-thin">
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start"></th>
                <th className="w-[10%] border-r-[1px] p-2 font-normal text-start">
                  Date
                </th>
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Type
                </th>
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Publié
                </th>
                <th className="w-[10%] border-r-[1px] p-2 font-normal text-start">
                  Chemise
                </th>
                <th className="w-[60%] border-r-[1px] p-2 font-normal text-start">
                  Nom
                </th>
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Opé.
                </th>
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Réd.
                </th>
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start"></th>
                <th className="w-[5%] border-r-[1px] p-2 font-normal text-start"></th>
              </tr>
            </thead>
            <tbody className="flex flex-col w-full text-xs ">
              {documentsList?.map((document: any, index: number) => (
                <tr
                  key={index}
                  className="flex w-full border-b-[1px] border-[#cecece]"
                >
                  {document?.DOC_RGBCOLOR !== null ? (
                    <td className="w-[5%] flex justify-center items-center">
                      <div
                        className="2xl:w-5 2xl:h-5 w-3 h-3 rounded-full border-[1px]"
                        style={{
                          backgroundColor: delphi2html_color(
                            document.DOC_RGBCOLOR
                          ),
                        }}
                      />
                    </td>
                  ) : (
                    <td className="w-[5%] flex justify-center items-center">
                      <div className="2xl:w-5 2xl:h-5 w-3 h-3 rounded-full border-[1px] bg-white" />
                    </td>
                  )}
                  <td className="w-[10%] p-2">
                    {dateFormattedFr(document.DOC_DATE)}
                  </td>
                  <td className="w-[5%] p-2">{document.DOC_FILE}</td>
                  {document.DOC_DATEEMISSION ? (
                    <td className="w-[5%] p-2">
                      <img src={plane} alt="plane" className="w-5 h-5 " />
                    </td>
                  ) : (
                    <td className="w-[5%] p-2"></td>
                  )}
                  <td className="w-[10%] p-2">{document.PAR_NOM}</td>
                  <td className="w-[60%] p-2">{document.DOC_NOM}</td>
                  <td className="w-[5%] p-2">{document.DOC_UTIL}</td>
                  <td className="w-[5%] p-2">{document.DOC_REDAC}</td>
                  <td className="w-[5%] p-2 flex justify-center items-center">
                    <button onClick={() => handleDownload(document.DOC_REF)}>
                      <img src={eye} alt="download" className="w-5 h-5" />
                    </button>
                  </td>
                  <td className="w-[5%] p-2 flex justify-center items-center">
                    {/* <button
                      onClick={() => handleDeleteDocument(document.DOC_REF)}
                    >
                      <img src={trash} alt="trash" className="w-5 h-5" />
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DocumentsList;
