import { useState } from "react";
import { ModalHook } from "../interface/hooks";

/**
 * @hook
 * @name useModal
 * @author Iris Succi
 * @date 2023
  * @description Un hook qui permet de gérer l'ouverture et la fermeture d'une modal.
 */

const useModal = (): ModalHook => {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (content: JSX.Element): void => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  return { modalContent, isModalOpen, openModal, closeModal };
};

export default useModal;
