/**
 * @component
 * @name Footer
 * @description Un composant qui affiche le pied de page de l'application.
 * @author Iris Succi
 * @date 2023
 */
const Footer = () => {
  return (
    <footer className="fixed bottom-0 w-screen h-10 m-auto flex border-t-[1px] border-slate-200 justify-center items-center bg-bc-lightGrey">
      <p className="text-slate-400 font-thin italic ">Buroclic-online</p>
    </footer>
  );
};

export default Footer;
