import logo from "../../assets/BUROCLIC-ONLINE_ROSE.png";
import frais from "../../assets/frais-plus.png";
import calendrier from "../../assets/agenda-plus.png";
import temps from "../../assets/temps-plus.png";
import { useNavigate, useParams } from "react-router-dom";
import { useRightContext } from "../../context/RightContext";
import { useRightTimeContext } from "../../context/RightTimeContext";
import { useRightInvoiceContext } from "../../context/RightInvoiceContext";
import { useRightStateContext } from "../../context/RightStateContext";
import {
  getRightsInvoices,
  getRightsState,
  getRightsTime,
} from "../../utils/user";
import dataMenu from "../../data/dataMenu";

/**
 * @component
 * @name Menu
 * @description Un composant qui affiche le menu de l'application avec les différentes fonctionnalités accessibles par l'utilisateur en suivant les droits.
 * @author Iris Succi
 * @date 2025
 */
const Menu = () => {
  //Hook
  const navigate = useNavigate();

  //Context
  const { buroFact, buroTime, chrono, easyFile, etats, cost } =
    useRightContext();
  const { alias } = useParams();
  const {
    setEditLimit,
    setVisuLimit,
    setTimeEntry,
    setDeleteTime,
    setValidatedTime,
  } = useRightTimeContext();
  const {
    setAcompteRight,
    setAvoirRight,
    setBordereauxRight,
    setProvisionRight,
    setDeboursExtRight,
    setDeboursIntRight,
    setEncaissementRight,
    setFactureRight,
    setHonorairesRight,
    setRelanceRight,
  } = useRightInvoiceContext();
  const {
    setEncaissementStateRight,
    setFacturationStateRight,
    setProductionStateRight,
  } = useRightStateContext();

  // Récupère les droits de l'utilisateur
  const rights: { [key: string]: number } = {
    BuroFact: buroFact,
    BuroTime: buroTime,
    "Chrono des documents": chrono,
    EasyFile: easyFile,
    Etats: etats,
    "Notes de frais": cost,
  };

  /**
 * @function
 * @name Menu
 * @description Fonction qui permet de gérer le clic sur un bouton du menu et de rediriger l'utilisateur vers la page correspondante. Elle permet également de récupérer les droits de l'utilisateur pour les afficher sur la page correspondante.
 * @author Iris Succi
 * @date 2025
 */
  const handleCardClick = async (item: any) => {
    if (item.right === "BuroTime") {
      try {
        const response = await getRightsTime();
        localStorage.setItem("rightsTime", JSON.stringify(response.data));
        setEditLimit(
          response.data["Edition limitée aux temps de l'acteur en cours"]
        );
        setVisuLimit(
          response.data["Visualisation limitée aux temps de l'acteur en cours"]
        );
        setTimeEntry(response.data["Saisie temps"]);
        setDeleteTime(response.data["Suppression des temps interdite"]);
        setValidatedTime(response.data["Validation temps"]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    } else if (item.right === "BuroFact") {
      try {
        const response = await getRightsInvoices();
        localStorage.setItem("rightsInvoice", JSON.stringify(response.data));
        setAcompteRight(response.data["Acomptes Clients"]);
        setAvoirRight(response.data["Avoirs sur factures"]);
        setBordereauxRight(response.data["Bordereaux"]);
        setProvisionRight(response.data["Demandes de provision"]);
        setDeboursExtRight(response.data["Débours externes"]);
        setDeboursIntRight(response.data["Débours internes"]);
        setEncaissementRight(response.data["Encaissements"]);
        setFactureRight(response.data["Factures"]);
        setHonorairesRight(response.data["Honoraires"]);
        setRelanceRight(response.data["Relances"]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    } else if (item.right === "Etats") {
      const response = await getRightsState();
      localStorage.setItem("rightsState", JSON.stringify(response.data));
      setEncaissementStateRight(response.data["Etats des encaissements"]);
      setFacturationStateRight(response.data["Etats de facturation"]);
      setProductionStateRight(response.data["Etats de production"]);
    }
    navigate(`/${alias}${item.path}`);
  };

  return (
    <div className="flex flex-col justify-start items-center mr-10 2xl:mr-20">
      <img src={logo} alt="logo buroclic" className="w-16 h-16" />
      {dataMenu.map(
        (item) =>
          rights[item.right] === 1 && (
            <button
              key={item.id}
              onClick={() => handleCardClick(item)}
              className="p-4 border-2 rounded-full border-bc-orange mt-5"
            >
              <img src={item.logo} alt={item.title} className="w-10 h-10" />
            </button>
          )
      )}
      <div className="w-20 h-auto mt-5 bg-bc-orange-pale border-2 border-bc-orange-pale rounded-md flex flex-col justify-center items-center">
        <button
          onClick={() => console.log("clicked")}
          className="p-2 border-2 rounded-full border-bc-orange mt-5"
        >
          <img src={calendrier} alt="calendrier" className="w-8 h-8" />
        </button>
        <button
          onClick={() => console.log("clicked")}
          className="p-2 border-2 rounded-full border-bc-orange mt-5"
        >
          <img src={frais} alt="calendrier" className="w-8 h-8" />
        </button>
        <button
          onClick={() => console.log("clicked")}
          className="p-2 border-2 rounded-full border-bc-orange mt-5 mb-5"
        >
          <img src={temps} alt="calendrier" className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};

export default Menu;
