import { useEffect } from "react";
import { useSousFicheStore } from "../../../../store/Crm/SousFiche";
import { useLoadingStore } from "../../../../store/utils/loading";
import { getTypesSousFiche } from "../../../../utils/customerFile";
import { useExplorer } from "../../../../store/Crm/Explorer";
import RenderSousFicheEC from "./RenderSousFicheEC";
import RenderSousFicheGaranties from "./RenderSousFicheGaranties";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import {
  createSousFicheEC,
  getParticipantsFields,
} from "../../../../utils/participants";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";
import { errorFetch, sousFicheCreated } from "../../../../utils/toaster";

 /**
 * @component
 * @name CreateSousFiche
 * @author Succi Iris
 * @date 2025
 * @description CreateSousFiche est un composant qui permet de créer une sous fiche
 */
const CreateSousFiche = () => {
  const { isLoading, setIsLoading } = useLoadingStore();
  const {
    choiceTypeSousFiche,
    setChoiceTypeSousFiche,
    typesSousFiches,
    setTypesSousFiches,
    setFields,
    localCity,
    localCp,
    data,
    setLocalCity,
    setData,
    setLocalCp,
  } = useSousFicheStore();
  const { PDOS_ID, PENT_ID, dosRef, ficRefIntervenant, updateExplorer, setUpdateExplorer } = useExplorer();
  const { setChoiceCreate } = useIntervenantStore();

  //Recupère les types de sous fiches
  useEffect(() => {
    getTypesSousFiche(PDOS_ID, PENT_ID)
      .then((res) => {
        const formattedData = res.data.map((item: any) => {
          return {
            // Obligé de concaténer les 2 ID pour avoir une valeur unique
            value: item.PFIC_ID + "-" + item.PENT_ID,
            label: item.PENT_NOM,
          };
        });
        setTypesSousFiches(formattedData);

      })
      .catch((err) => {
        console.log(err);
      });
  }, [choiceTypeSousFiche]);

  useEffect(() => {
    setIsLoading(true);
    const PFIC_ID_2 = choiceTypeSousFiche?.value?.split("-")[0];
    const PENT_ID_2 = choiceTypeSousFiche?.value?.split("-")[1];
    getParticipantsFields(PFIC_ID_2, PENT_ID_2, PDOS_ID)
      .then((res) => {
        setFields(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [choiceTypeSousFiche]);

   /**
 * @funciton
 * @name handleCreate
 * @author Succi Iris
 * @date 2025
 * @description handleCreate est une fonction qui permet de créer une sous fiche
 */
  const handleCreate = () => {
    setIsLoading(true);

    const PFIC_ID_2 = choiceTypeSousFiche?.value?.split("-")[0];
    const PENT_ID_2 = choiceTypeSousFiche?.value?.split("-")[1];
    const dataToSend = {
      PFIC_ID: parseInt(PFIC_ID_2),
      PENT_ID: parseInt(PENT_ID_2),
      FIC_REF: ficRefIntervenant,
      PDOS_ID: PDOS_ID,
      "Adresse principale (CP)": localCp,
      "Adresse principale (Ville)": localCity,
      DOS_REF: dosRef,
      ...data,
    };

    createSousFicheEC(dataToSend)
      .then(() => {
        setData({});
        setLocalCp("");
        setChoiceTypeSousFiche(null);
        setLocalCity("");
        setChoiceCreate("");
        sousFicheCreated();
        setUpdateExplorer(!updateExplorer)
        setIsLoading(false);
      })
      .catch((err) => {
        errorFetch();
        setIsLoading(false);
        console.log(err);
      });
  };

  const reset = () => {
    setData({});
    setChoiceCreate("");
  };

  return (
    <div className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-md overflow-y-scroll text-sm">
      <h2 className="my-5 text-xl font-bold">Création d'une sous fiche</h2>

      <div className="flex flex-col justify-start items-start w-full">
        <div className="grid grid-cols-5  w-11/12 m-auto gap-4">
          <div className="col-start-1 col-end-2 w-full flex justify-start items-center">
            <p>Type de sous fiche </p>
          </div>
          <div className="col-start-2 col-end-3 w-full">
            <select
              name="typeSousFiche"
              id="typeSousFiche"
              className="border-[1px] rounded-md w-full p-2"
              onChange={(e) => {
                const selectedOption = typesSousFiches?.find(
                  (item: any) => item.value === e.target.value
                );
                setChoiceTypeSousFiche(selectedOption);
              }}
            >
              <option value="" className="text-slate-300 "></option>
              {typesSousFiches?.map((item: any) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <hr className="col-start-2 col-end-5 my-2" />
        </div>
        {!isLoading ? (
          <div className="w-full flex flex-col justify-start items-center">
            {choiceTypeSousFiche?.label === "Expert comptable" ? (
              <RenderSousFicheEC />
            ) : choiceTypeSousFiche?.label === "Garanties" ? (
              <RenderSousFicheGaranties />
            ) : null}

            <hr className="col-start-2 col-end-5 my-2" />

            <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
              <div className="col-start-4 col-end-5 w-2/3 mb-5">
                <button
                  className="w-full mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                  onClick={() => reset()}
                >
                  Annuler
                </button>
              </div>
              <div className="col-start-5 col-end-6 w-2/3 mb-5">
                <button
                  className="w-full mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                  onClick={() => handleCreate()}
                >
                  Créer
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
            <CircleLoarder />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateSousFiche;
