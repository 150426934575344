import React, { useEffect } from "react";
import Select from "react-select";
import { filterModalTimeProps } from "../../../interface/modales";
import SelectUsersFilterTime from "../selects/SelectUsersFilterTime";
import { getByInterval } from "../../../utils/TimeManager/time";
import {
  getAllDossiers,
  getClasseurs,
  getDossiers,
} from "../../../utils/folders";
import { SelectOptionDossier } from "../../../interface/selects";
import { getMissionByDosRef, getModels } from "../../../utils/missions";
import { getAllUsers } from "../../../utils/user";
import { nodatamatches } from "../../../utils/toaster";
import {
  getTimeFilterLibelles,
  getTimeFilterOrigin,
} from "../../../utils/TimeManager/timeFilter";
import { useDataStore } from "../../../store/TimeManagement/dataStore";
import { useListStore } from "../../../store/TimeManagement/listStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { getVarDos, getVarDosLib } from "../../../utils/customerFile";
import { filterStore } from "../../../store/TimeManagement/filterStore";
import { useTranslation } from "react-i18next";
import DatepickerForFilter from "../../Invoice/modal/Filters/DatePickerForFilter";
import { dateFormattedWithNullAlorsEnfaiteCestPasUnNullMaisUnStringVideDeso } from "../../../utils/TimeManager/timeUtils";

/**
 * @component
 * @name FilterTime
 * @author Succi Iris
 * @date 2023
 * @description Permet de filtrer les temps.
 *
 */
const FilterTime: React.FC<filterModalTimeProps> = ({ closeModal }) => {
  // Translate
  const { t } = useTranslation();

  // Store
  const { setDataMonth } = useDataStore();
  const { setDataFilterList } = useListStore();
  const { setIsLoading } = useLoadingStore();
  const {
    missions,
    setMissions,
    classeurs,
    setClasseurs,
    models,
    setModels,
    varFile,
    setVarFile,
    varFileLib,
    setVarFileLib,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    selectedClasseur,
    setSelectedClasseur,
    selectedMisRef,
    setSelectedMisRef,
    selectedVarFileLib,
    setSelectedVarFileLib,
    users,
    setUsers,
    selectedUsersActeurs,
    setSelectedUsersActeurs,
    selectedAllUsers,
    setSelectedAllUsers,
    selectedOperator,
    setSelectedOperator,
    selectedTime,
    setSelectedTime,
    selectedStateTime,
    setSelectedStateTime,
    selectedStateMission,
    setSelectedStateMission,
    selectedFileOption,
    setSelectedFileOption,
    selectedOriginObject,
    setSelectedOriginObject,
    selectedFile,
    setSelectedFile,
    selectedMission,
    setSelectedMission,
    selectedOrigin,
    setSelectedOrigin,
    selectedLibelle,
    setSelectedLibelle,
    selectedModel,
    setSelectedModel,
    selectedVarFile,
    setSelectedVarFile,
    fileOptions,
    setFileOptions,
    origins,
    setOrigins,
    libelles,
    setLibelles,
    setSelectedLibelleObject,
  } = filterStore();

  useEffect(() => {
    if (selectedFile == 0) {
      setMissions([]);
      setSelectedMission("");
      setSelectedMisRef(0);
      setSelectedModel("");
    }
  }, [selectedFile]);

  useEffect(() => {
    localStorage.setItem("startDateFilter", JSON.stringify(startDateFilter));
    localStorage.setItem("endDateFilter", JSON.stringify(endDateFilter));
  }, [startDateFilter, endDateFilter]);

  useEffect(() => {
    getAllUsers()
      .then((res) => {
        if (res?.data) {
          const sortedUsers = res.data.sort((a: any, b: any) =>
            a.identifiant.localeCompare(b.identifiant)
          );
          setUsers(sortedUsers);
          const allUserIds = sortedUsers.map((user: any) => user.identifiant);
          setSelectedAllUsers(allUserIds);
        } else {
          console.log("No data found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // Fonction pour récupérer tous les classeurs
  useEffect(() => {
    getClasseurs()
      .then((res) => {
        const formattedClasseurs = res.data.map((classeur: any) => ({
          value: classeur.PDOS_ID,
          label: classeur.PDOS_NOM,
        }));
        setClasseurs(formattedClasseurs);
      })
      .catch((err) => console.log(err));
  }, []);

  // Fonction pour récupérer tous les dossiers en fonction du classeur sélectionné
  useEffect(() => {
    const fetchDossiers = async () => {
      try {
        let res;
        if (selectedClasseur !== 0) {
          res = await getDossiers(selectedClasseur);
        } else {
          res = await getAllDossiers();
        }

        const formattedFiles = res.data
          .map((file: any) => ({
            value: file.DOS_REF,
            label: file.FIC_TITRE_CLIENT,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));

        setFileOptions(formattedFiles);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDossiers();

    const storedValue = localStorage.getItem("selectedFile");
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      setSelectedFile(parsedValue?.value);
      setSelectedFileOption(parsedValue);
    }
  }, [selectedClasseur]);

  // Fonction pour récupérer toutes les missions en fonction du dossier sélectionné
  useEffect(() => {
    if (selectedFile !== 0) {
      getMissionByDosRef(selectedFile)
        .then((res) => {
          const filteredMissions = res?.data.reduce(
            (missions: any[], mission: any) => {
              if (mission.MIS_DEBUT !== null) {
                const missionYear = new Date(mission.MIS_DEBUT).getFullYear();

                const existingMission = missions.find(
                  (item) => item.MIS_LIBELLE === mission.MIS_LIBELLE
                );

                if (existingMission) {
                  const existingMissionYear = new Date(
                    existingMission.MIS_DEBUT
                  ).getFullYear();
                  if(startDateFilter !== null) {

                    if (
                      Math.abs(missionYear - startDateFilter.getFullYear()) <
                      Math.abs(
                        existingMissionYear - startDateFilter.getFullYear()
                        )
                        ) {
                          missions = missions.filter(
                            (item) => item.MIS_LIBELLE !== mission.MIS_LIBELLE
                            );
                            missions.push(mission);
                          }
                  }
                } else {
                  missions.push(mission);
                }
              } else {
                missions.push(mission);
              }

              const storedValue = localStorage.getItem("selectedMission");
              if (storedValue) {
                const parsedValue = JSON.parse(storedValue);
                missions.find((mission) => mission.value === parsedValue);
                setSelectedMission(parsedValue);
              }

              return missions;
            },
            []
          );

          const formattedMissions = filteredMissions.map((mission: any) => ({
            value: mission.MIS_CODE,
            label: mission.MIS_DEBUT
              ? `${new Date(mission.MIS_DEBUT).getFullYear()} - ${
                  mission.MIS_LIBELLE
                }`
              : mission.MIS_LIBELLE,
            misRef: mission.MIS_REF,
          }));

          setMissions(formattedMissions);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedFile, startDateFilter]);

  // Fonction pour récupérer toutes les origines et libellés en fonction du dossier sélectionné et de la mission
  useEffect(() => {
    getTimeFilterOrigin()
      .then((res) => {
        const formattedOrigins = res.data.map((origin: any) => ({
          value: origin.AOR_REF,
          label: origin.AOR_LIBELLE,
        }));
        setOrigins(formattedOrigins);
      })
      .catch((err) => console.log(err));

    const storedValue = localStorage.getItem("selectedOrigin");
    if (storedValue) {
      const originObject = origins.find(
        (origin) => origin.label === storedValue
      );
      setSelectedOriginObject(originObject);
    }
  }, [selectedFile, selectedMisRef, selectedMission]);

  // Fonction pour récupérer tous les libellés en fonction de l'origine sélectionnée
  useEffect(() => {
    if (selectedOrigin !== "" && selectedOriginObject) {
      getTimeFilterLibelles(selectedOriginObject.value)
        .then((res) => {
          const formattedLibelles = res.data.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_LIBELLE,
          }));
          setLibelles(formattedLibelles);
        })
        .catch((err) => console.log(err));
    }

    const storedValue = localStorage.getItem("selectedLibelle");
    if (storedValue) {
      libelles.find((libelle) => libelle.label === storedValue);
    }
  }, [selectedOrigin]);

  // Fonction pour récupérer tous les modèles
  useEffect(() => {
    getModels()
      .then((res) => {
        const formattedModels = res.data.map((classeur: any) => ({
          value: classeur.MIS_CODE,
          label: classeur.MIS_LIBELLE,
        }));
        setModels(formattedModels);
      })
      .catch((err) => console.log(err));

    if (selectedModel) {
      localStorage.setItem("selectedModel", selectedModel);
    } else {
      localStorage.removeItem("selectedModel");
    }
  }, []);

  // Fonction pour récupérer les variables des dossiers
  useEffect(() => {
    getVarDos()
      .then((res) => {
        setVarFile(res.data);
        if (selectedVarFile !== 0) {
          getVarDosLib(selectedVarFile)
            .then((res) => setVarFileLib(res.data))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
    if (selectedVarFile) {
      localStorage.setItem("selectedVarFile", selectedVarFile.toString());
    } else {
      localStorage.removeItem("selectedVarFile");
    }
  }, [selectedLibelle, selectedVarFile]);

  const handleFilter = () => {
    setIsLoading(true);
    closeModal();

    const selectedUsers =
      selectedAllUsers.length > 0 ? selectedAllUsers : selectedUsersActeurs;
    
    getByInterval(
      selectedUsers,
      dateFormattedWithNullAlorsEnfaiteCestPasUnNullMaisUnStringVideDeso(startDateFilter),
      dateFormattedWithNullAlorsEnfaiteCestPasUnNullMaisUnStringVideDeso(endDateFilter)
    )
      .then((res) => {
        let filteredData = res.data;
        // Filter by classeur
        if (selectedClasseur !== 0) {
          filteredData = filteredData.filter(
            (item: any) => item.TD_PDOS_ID === selectedClasseur
          );
        }
        // Filter by file
        if (selectedFile !== 0) {
          filteredData = filteredData.filter(
            (item: any) => item?.AA_DOS_REF === selectedFile
          );
        }

        // Filter by mission
        if (selectedMission !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.VM_MIS_CODE === selectedMission
          );
        }

        // Filter by model
        if (selectedModel !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.VM_MIS_CODE === selectedModel
          );
        }

        // Filter by users
        if (selectedUsersActeurs.length > 0) {
          filteredData = filteredData.filter((item: any) =>
            selectedUsersActeurs.includes(item.AA_REDACTEUR)
          );
        }

        if (selectedUsersActeurs.length > 0) {
          filteredData = filteredData.filter((item: any) =>
            selectedUsersActeurs.includes(item.AA_REDACTEUR)
          );
        }

        // Filter by time
        if (selectedOperator !== "" && selectedTime !== 0) {
          const selectedTimeNumber = parseFloat(selectedTime);
          switch (selectedOperator) {
            case "<":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_TEMPS < selectedTime
              );
              break;
            case ">":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_TEMPS > selectedTime
              );
              break;
            case "=":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_TEMPS === selectedTimeNumber
              );
              break;
            case "<=":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_TEMPS <= selectedTime
              );
              break;
            case ">=":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_TEMPS >= selectedTime
              );
              break;
            case "!==":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_TEMPS != selectedTime
              );
              break;
            default:
              break;
          }
        }

        if (selectedStateTime !== "toutes") {
          switch (selectedStateTime) {
            case "validées":
              filteredData = filteredData.filter(
                (item: any) => item.AA_ANA_REFPERE && item.AA_ANA_REFPERE !== 0
              );
              break;
            case "refusées":
              filteredData = filteredData.filter(
                (item: any) =>
                  item.AOR_OPTION_FACTURABLE === 1 && item.ANA_EMTHT === 0
              );
              break;
            default:
              break;
          }
        }

        if (selectedStateMission !== "toutes") {
          switch (selectedStateMission) {
            case "cloturée":
              filteredData = filteredData.filter(
                (item: any) => item.VM_MIS_ETAT === 1
              );
              break;
            case "en cours":
              filteredData = filteredData.filter(
                (item: any) => item.VM_MIS_ETAT === 0
              );
              break;
            case "annulée":
              filteredData = filteredData.filter(
                (item: any) => item.VM_MIS_ETAT === 2
              );
              break;
            default:
              break;
          }
        }

        if (selectedOrigin !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.AO_AOR_LIBELLE === selectedOrigin
          );
        }

        if (selectedLibelle !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.AA_ANA_LIBELLELIB === selectedLibelle
          );
        }

        if (selectedVarFile == 1) {
          filteredData = filteredData.filter((item: any) => {
            return (
              item.TD_DOS_VARLIB1 !== null &&
              item.TD_DOS_VARLIB1 == selectedVarFileLib
            );
          });
        } else if (selectedVarFile == 2) {
          filteredData = filteredData.filter(
            (item: any) => item.TD_DOS_VARLIB2 == selectedVarFileLib
          );
        } else if (selectedVarFile == 3) {
          filteredData = filteredData.filter(
            (item: any) => item.TD_DOS_VARLIB3 == selectedVarFileLib
          );
        } else if (selectedVarFile == 4) {
          filteredData = filteredData.filter(
            (item: any) => item.TD_DOS_VARLIB4 == selectedVarFileLib
          );
        } else if (selectedVarFile == 5) {
          filteredData = filteredData.filter(
            (item: any) => item.TD_DOS_VARLIB5 == selectedVarFileLib
          );
        }

        setDataFilterList(filteredData);
        if (filteredData.length === 0) {
          nodatamatches();
        } else {
          setDataMonth([]);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteFilter = () => {
    setSelectedAllUsers([]);
    setSelectedUsersActeurs([]);
    setSelectedClasseur(0);
    setSelectedOrigin("");
    setSelectedLibelle("");
    setSelectedFile(0);
    setSelectedMission("");
    setSelectedModel("");
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDataFilterList([]);
    setSelectedLibelleObject(null);
    setSelectedOriginObject(null);
    setSelectedOperator("");
    setSelectedTime(0);
    setSelectedFileOption(null);
    localStorage.removeItem("selectedMission");
    localStorage.removeItem("selectedOrigin");
    localStorage.removeItem("selectedLibelle");
    localStorage.removeItem("selectedModel");
    localStorage.removeItem("selectedClasseur");
    localStorage.removeItem("selectedVarFile");
    localStorage.removeItem("startDateFilter");
    localStorage.removeItem("endDateFilter");
    localStorage.removeItem("selectedFile");
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[55%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl text-center">{t("Filtrer les temps")}</h3>
            </div>
            {/*body*/}
            <div className="w-11/12 m-auto font-light mt-2 flex flex-col">
              <div className="flex w-[70%] m-auto justify-between items-center">
                <div className="flex justify-center items-center z-50">
                  <p className="mr-2 mb-2">{t("Du")} :</p>
                  <DatepickerForFilter
                    startDate={startDateFilter}
                    setStartDate={(date: Date | null) => {
                      if (date) setStartDateFilter(date);
                    }}
                  />
                </div>
                <div className="flex justify-center items-center z-50">
                  <p className="mr-2 mb-2">{t("Au")} :</p>
                  <DatepickerForFilter
                    startDate={endDateFilter}
                    setStartDate={(date: Date | null) => {
                      if (date) setEndDateFilter(date);
                    }}
                  />
                </div>
              </div>
              <hr className="w-8/12 m-auto mb-2" />
              <div className="flex flex-col w-10/12 m-auto justify-between items-center">
                <div className="grid grid-cols-5 w-full">
                  <div className="flex flex-col col-start-1 col-end-3">
                    <p className="mr-2">{t("Dossier")}: </p>
                    <Select
                      options={fileOptions}
                      value={selectedFileOption}
                      isClearable
                      className="w-full h-9 z-30 font-thin"
                      isSearchable
                      onChange={(
                        selectedOption: SelectOptionDossier | null
                      ) => {
                        const selectedValue = selectedOption?.value || 0;
                        setSelectedFile(selectedValue);
                        setSelectedFileOption(selectedOption);
                        localStorage.setItem(
                          "selectedFile",
                          JSON.stringify(selectedOption)
                        );
                      }}
                    />
                  </div>

                  <div className="flex flex-col col-start-4 col-end-6 ">
                    <p className="mr-2">{t("Mission")}: </p>
                    <select
                      name="mission"
                      id="mission"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      value={selectedMission}
                      onChange={(event) => {
                        const selectedValue = event.target.value || "";
                        const selectedMissionObject = missions.find(
                          (mission) => mission.value === selectedValue
                        );
                        if (selectedMissionObject) {
                          setSelectedMission(selectedMissionObject.value);
                          setSelectedMisRef(selectedMissionObject.misRef);
                          localStorage.setItem(
                            "selectedMission",
                            JSON.stringify(selectedMissionObject.value)
                          );
                        }
                      }}
                    >
                      <option value={""}></option>
                      {missions.map((mission) => (
                        <option value={mission?.value}>{mission?.label}</option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-1 col-end-3 mt-2">
                    <p className="mr-3">{t("Origine")}: </p>
                    <select
                      name="Origine"
                      id="Origine"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      value={selectedOrigin || ""}
                      onChange={(event) => {
                        setSelectedOrigin(event.target.value);
                        localStorage.setItem(
                          "selectedOrigin",
                          event.target.value
                        );
                        const originObject = origins.find(
                          (origin) => origin.label === event.target.value
                        );
                        setSelectedOriginObject(originObject);
                      }}
                    >
                      <option value={""}></option>
                      {origins.map((origin) => (
                        <option key={origin.value} value={origin.label}>
                          {origin.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-4 col-end-6 mt-2">
                    <p className="mr-5">{t("Libellé")}: </p>
                    <select
                      name="Libelle"
                      id="Libelle"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      onChange={(event) => {
                        setSelectedLibelle(event.target.value);
                        localStorage.setItem(
                          "selectedLibelle",
                          event.target.value
                        );
                        const libelleObjet = libelles.find(
                          (libelle) => libelle.label === event.target.value
                        );
                        setSelectedLibelleObject(libelleObjet);
                      }}
                    >
                      <option value={""}></option>
                      {libelles.map((libelle) => (
                        <option key={libelle.value} value={libelle.label}>
                          {libelle.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-1 col-end-3 mt-2">
                    <p className="mr-5">{t("Variable")}: </p>
                    <select
                      name="Variable"
                      id="Variable"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-10 font-thin"
                      value={selectedVarFile}
                      onChange={(event) =>
                        setSelectedVarFile(Number(event.target.value))
                      }
                    >
                      <option value={""}></option>
                      {varFile.map((varFile) => (
                        <option
                          key={varFile?.varDosNumber}
                          value={varFile?.varDosNumber}
                        >
                          {varFile?.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-4 col-end-6 mt-2">
                    <p className="mr-5">
                      {t("Variable")} {t("Libellé")}:{" "}
                    </p>
                    <select
                      name="Variable Libelle"
                      id="VariableLibelle"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-10 font-thin"
                      value={selectedVarFileLib}
                      onChange={(event) =>
                        setSelectedVarFileLib(event.target.value)
                      }
                    >
                      <option value={""}></option>
                      {varFileLib.map((varFileLib) => (
                        <option key={varFileLib?.code} value={varFileLib?.code}>
                          {varFileLib?.libelle}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-1 col-end-3 mt-2">
                    <p className="mr-5">{t("Modèle")}: </p>
                    <select
                      name="Model"
                      id="Model"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      value={selectedModel}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setSelectedModel(selectedValue);
                        localStorage.setItem("selectedModel", selectedValue);
                      }}
                    >
                      <option value={""}></option>
                      {models.map((model) => (
                        <option key={model.value} value={model.value}>
                          {model.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-4 col-end-6 mt-2">
                    <p className="">{t("Acteur")}:</p>
                    <SelectUsersFilterTime
                      users={users}
                      selectedUsersActeurs={selectedUsersActeurs}
                      setSelectedUsersActeurs={setSelectedUsersActeurs}
                      setSelectedAllUsers={setSelectedAllUsers}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-10/12 m-auto mt-4">
                <div className="grid grid-cols-5 w-full">
                  <div className="flex flex-col col-start-1 col-end-3 border-[1px] rounded-md bg-bc-lightGrey">
                    <p className="mr-6 p-1">
                      {t("Etat")} {t("Mission")} :
                    </p>
                    <div className="grid grid-cols-4 w-11/12 m-auto">
                      <label
                        className="mr-2 col-start-1 col-end-3"
                        htmlFor="radio-tout"
                      >
                        <input
                          type="radio"
                          id="radio-tout"
                          name="etat"
                          className="mr-2 ml-2 mt-2"
                          onChange={() => setSelectedStateMission("tout")}
                          checked={selectedStateMission === "tout"}
                        />
                        {t("Tout")}
                      </label>

                      <label
                        className="mr-2 col-start-3 col-end-5"
                        htmlFor="radio-cloturee"
                      >
                        <input
                          type="radio"
                          id="radio-cloturee"
                          name="etat"
                          className="mr-2 ml-2 mt-2"
                          onChange={() => setSelectedStateMission("cloturée")}
                          checked={selectedStateMission === "cloturée"}
                        />
                        {t("Cloturée")}
                      </label>

                      <label
                        className="mr-2 col-start-1 col-end-3"
                        htmlFor="radio-en-cours"
                      >
                        <input
                          type="radio"
                          id="radio-en-cours"
                          name="etat"
                          className="mr-2 ml-2 mt-2"
                          onChange={() => setSelectedStateMission("en cours")}
                          checked={selectedStateMission === "en cours"}
                        />
                        {t("En cours")}
                      </label>
                      <label
                        className="mr-2 col-start-3 col-end-5"
                        htmlFor="radio-annule"
                      >
                        <input
                          type="radio"
                          id="radio-annule"
                          name="etat"
                          className="mr-2 ml-2 mt-2"
                          onChange={() => setSelectedStateMission("annulé")}
                          checked={selectedStateMission === "annulé"}
                        />
                        {t("Annuler")}
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col col-start-4 col-end-6 border-[1px] rounded-md bg-bc-lightGrey">
                    <p className="p-1">{t("Etat")}:</p>
                    <label
                      className="mr-2 ml-4"
                      htmlFor="radio-toutes-ecritures"
                    >
                      <input
                        type="radio"
                        id="radio-toutes-ecritures"
                        name="autres"
                        className="mr-2 ml-2"
                        onChange={() => setSelectedStateTime("toutes")}
                        checked={selectedStateTime === "toutes"}
                      />
                      {t("Toutes les écritures")}
                    </label>
                    <label
                      className="mr-2 ml-4"
                      htmlFor="radio-ecritures-pointees"
                    >
                      <input
                        type="radio"
                        id="radio-ecritures-pointees"
                        name="autres"
                        className="mr-2 ml-2"
                        onChange={() => setSelectedStateTime("validées")}
                        checked={selectedStateTime === "validées"}
                      />
                      {t("Ecritures validées")}
                    </label>
                    <label
                      className="mr-2 ml-4"
                      htmlFor="radio-ecritures-non-pointees"
                    >
                      <input
                        type="radio"
                        id="radio-ecritures-non-pointees"
                        name="autres"
                        className="mr-2 ml-2"
                        onChange={() => setSelectedStateTime("refusées")}
                        checked={selectedStateTime === "refusées"}
                      />
                      {t("Ecriture non validées")}
                    </label>
                  </div>
                </div>
              </div>

              <hr className="w-8/12 m-auto mb-2 mt-4" />
              <div className="flex flex-col w-10/12 m-auto justify-between items-center">
                <div className="grid grid-cols-5 w-full m-auto">
                  <div className="flex flex-col w-full col-start-1 col-end-3">
                    <p>{t("Classeur")}: </p>
                    <select
                      name="classeur"
                      id="classeur"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1  font-thin mb-2"
                      value={selectedClasseur}
                      onChange={(event) => {
                        const selectedValue = Number(event.target.value);
                        setSelectedClasseur(selectedValue);
                        localStorage.setItem(
                          "selectedClasseur",
                          String(selectedValue)
                        );
                      }}
                    >
                      <option value={""}></option>
                      {classeurs?.map((classeur) => (
                        <option value={classeur?.value}>
                          {classeur?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-start-4 col-end-6 w-full">
                    <p className="mr-5">{t("Temps")}: </p>
                    <select
                      name="Temps"
                      id="Temps"
                      className="w-14 border-[1px] rounded border-gray-300 h-9 p-1 z-10 font-thin"
                      value={selectedOperator}
                      onChange={(e) => setSelectedOperator(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value=">"> {">"} </option>
                      <option value="<"> {"<"} </option>
                      <option value="="> {"="} </option>
                      <option value=">="> {">="} </option>
                      <option value="<="> {"<="} </option>
                      <option value="≠"> {"≠"} </option>
                    </select>
                    <input
                      type="text"
                      className="w-20 h-9 border-[1px] rounded border-gray-300 ml-5 p-2"
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-bc-orange background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  deleteFilter();
                  closeModal();
                }}
              >
                {t("Effacer les filtres")}
              </button>
              <button
                className="text-bc-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => handleFilter()}
              >
                {t("Valider")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default FilterTime;
