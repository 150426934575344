import { useState } from "react";
import { useCreateInvoiceStore } from "../store/Invoice/createInvoice";

// Types
type DataItem = {
  ANA_REF: number;
};

/**
 * @hook
 * @name useSelectAll
 * @author Iris Succi
 * @date 2023
 * @description Un hook qui permet de gérer la sélection de toutes les factures.
 */

export const useSelectAll = (data: DataItem[]) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { selectedIdsInvoices, setSelectedIdsInvoices } =
    useCreateInvoiceStore();

  const handleSelectAllChange = () => {
    setSelectAll((prev) => !prev);

    if (!selectAll) {
      setSelectedIdsInvoices(
        data
          .map((item) => item.ANA_REF)
          .filter((item): item is number => typeof item === "number")
      );
    } else {
      setSelectedIdsInvoices([]);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
      setSelectAll(false);
    }
  };

  return {
    selectAll,
    selectedIdsInvoices,
    handleSelectAllChange,
    handleCheckboxChange,
  };
};
