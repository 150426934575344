import { useCreateFeesOrInvoices } from "../../../../../store/Invoice/buttonsStore";
import { useCreateInvoiceStore } from "../../../../../store/Invoice/createInvoice";
import chevron from "../../../../../assets/icons/chevron-right.svg";
import { useEffect } from "react";
import { getProcessus } from "../../../../../utils/Invoice/processus";
import { createBillableItemsStore } from "../../../../../store/Invoice/createBillableItemsStore";
import { getAllDossiers } from "../../../../../utils/folders";
import Select from "react-select";
import CreateFeeOrDeboursInt from "./CreateFeeOrDeboursInt";
import { useCreateFee } from "../../../../../store/Invoice/createFeeStore";
import { amountsStore } from "../../../../../store/Invoice/amountsStore";
import {
  errorEntry,
  errorSendNewTime,
  successFee,
  successUpdateData,
} from "../../../../../utils/toaster";
import { useLoadingStore } from "../../../../../store/utils/loading";
import {
  getFee,
  postNewFee,
  updateFee,
} from "../../../../../utils/Invoice/fees";
import { useTabsStore } from "../../../../../store/Invoice/tabsStore";
import CreateDeboursExt from "./CreateDeboursExt";
import { amountsForDEStore } from "../../../../../store/Invoice/amountsForDE";
import { useDEStore } from "../../../../../store/Invoice/createDEStore";

/**
 * @component
 * @name CreateBillableItems
 * @author Succi Iris
 * @date 2024
 * @description Création d'un élément facturable avant la selection de la mission
 */
const CreateBillableItems = () => {
  const {
    animationClass,
    setAnimationClass,
    displayDivFee,
    setDisplayDivFee,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();
  const { createFees, setCreateFees, billableItem, setbillableItem } =
    useCreateFeesOrInvoices();
  const {
    processus,
    setProcessus,
    selectedProcessus,
    setSelectedProcessus,
    files,
    setFiles,
    selectedFile,
    setSelectedFile,
    setUpdateData,
    updateData,
  } = createBillableItemsStore();
  const {
    quantityDE,
    unitPriceSell,
    unitPriceBuy,
    htSell,
    tvaSell,
    htBuy,
    ttcBuy,
    ttcSell,
    selectedTvaRateDE,
    nsSell,
    nsBuy,
    tvaBuy,
  } = amountsForDEStore();
  const { tva, selectedTvaRate, ht, ttc, unitPrice, quantity, resetAmounts } =
    amountsStore();
  const { setIsLoading } = useLoadingStore();
  const { setChoiceTab, setSendData } = useTabsStore();
  const { resetAmountsForDE } = amountsForDEStore();
  const {
    selectedMission,
    selectedOrigin,
    selectedLibelle,
    comment,
    selectedActor,
    provisionRequest,
    resetFee,
    modeFee,
    setModeFee,
  } = useCreateFee();
  const {
    selectedCustomer,
    selectedMissionDE,
    selectedOriginDE,
    selectedLibelleDE,
    selectedActorDE,
    selectedSubscriptionDE,
    commentDE,
    PFIC_ID,
    FIC_CLE,
    piece,
    libelleCpt,
    resetDeStore,
  } = useDEStore();

  /**
   * @function
   * @author Succi Iris
   * @date 2023
   * @description Gere l'affichage du formulaire de création d'un élément facturable
   */
  useEffect(() => {
    if (createFees === true) {
      setDisplayDivFee(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDivFee(false);
      }, 300);
    }
  }, [createFees]);

  // Call api pour récupérer les processus et les dossiers
  useEffect(() => {
    if (createFees) {
      getProcessus()
        .then((res) => {
          const formattedFiles = res.data.map((processus: any) => ({
            value: processus.id,
            label: processus.text,
          }));
          setProcessus(formattedFiles);
        })
        .catch((err) => console.log(err));
      getAllDossiers()
        .then((res) => {
          const formattedFiles = res.data.map((file: any) => ({
            value: file.DOS_REF,
            label: file.FIC_TITRE_CLIENT,
          }));
          setFiles(formattedFiles);
        })
        .catch((err) => console.log(err));
    }
  }, [createFees]);

  const resetAll = () => {
    setModeFee("");
    setCreateFees(false);
    setChoiceTab("Honoraire");
    setIsLoading(false);
    setSendData((prev) => !prev);
    resetAmounts();
    setbillableItem("");
    resetAmountsForDE();
    resetDeStore();
    resetFee();
  };

  /**
   * @function
   * @name initialiseData
   * @description Initialise les données pour la modification
   * @author Succi Iris
   * @date 2024
   */
  useEffect(() => {
    const initialiseData = async () => {
      if (modeFee === "edit") {
        try {
          const res = await getFee(selectedIdsInvoices[0]);
          const data = res.data[0];
          setUpdateData(data);
          setSelectedProcessus(data.AOR_PROCESSUS[0].id);
          setSelectedFile(data.DOS_REF);
        } catch (err) {
          console.log(err);
        }
      }
    };

    initialiseData();
  }, [selectedIdsInvoices]);

  useEffect(() => {
    if (selectedFile && selectedProcessus == 2) setbillableItem("Honoraire");
    else if (selectedFile && selectedProcessus == 3)
      setbillableItem("Debours interne");
    else if (selectedFile && selectedProcessus == 4)
      setbillableItem("Debours externe");
  }, [selectedProcessus, selectedFile, modeFee, selectedIdsInvoices]);

  /**
   * @function
   * @author Succi Iris
   * @date 2024
   * @description Création d'un honoraire ou d'un débours interne
   */
  const handleCreateFeeOrDeboursInt = async () => {
    if (selectedProcessus === 0 || selectedFile === 0) {
      return errorEntry();
    }
    setIsLoading(true);

    const dataFee = {
      ANA_CODELIB: selectedLibelle,
      ANA_DATEREALISATION: new Date(),
      ANA_EPRIXUNITAIRE: Number(unitPrice),
      ANA_QUANTITE: Number(quantity),
      ANA_EMTHT: ht,
      ANA_EMTTVA: tva,
      ANA_EMTNS: 0,
      ANA_EMTTTC: ttc,
      ANA_NOTES: comment,
      ANA_REDACTEUR: selectedActor,
      AOR_REF: selectedOrigin,
      DOS_REF: selectedFile,
      MIS_REF: selectedMission,
      TVA_CLE: selectedTvaRate,
      ANA_DP: provisionRequest === "false" ? 0 : 1,
      AOR_PROCESSUS: selectedProcessus,
    };

    try {
      setDisplayDivFee(false);
      await postNewFee(dataFee);
      resetAll();
      successFee();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
      setCreateFees(false);
      setChoiceTab("Honoraire");
    }
  };

  /**
   * @function
   * @name handleUpdateFee
   * @author Succi Iris
   * @date 2023
   * @description Mise à jour d'un honoraire ou d'un débours interne
   */
  const handleUpdateFees = async () => {
    const updateNewData = {
      ANA_CODELIB: selectedLibelle,
      ANA_DATEREALISATION: new Date(),
      ANA_EPRIXUNITAIRE: Number(unitPrice),
      ANA_QUANTITE: Number(quantity),
      ANA_EMTHT: ht,
      ANA_EMTTVA: tva,
      ANA_EMTNS: 0,
      ANA_EMTTTC: ttc,
      ANA_NOTES: comment,
      ANA_REDACTEUR: selectedActor,
      AOR_REF: selectedOrigin,
      DOS_REF: selectedFile,
      MIS_REF: selectedMission,
      TVA_CLE: selectedTvaRate,
      ANA_DP: provisionRequest === "false" ? 0 : 1,
    };

    setIsLoading(true);

    try {
      setDisplayDivFee(false);
      const response = await updateFee(selectedIdsInvoices[0], updateNewData);
      console.log("Succès:", response);
      resetAll();
      setSelectedIdsInvoices((_prev) => []);
      successUpdateData();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
      setSelectedIdsInvoices((_prev) => []);
      setUpdateData({});
      resetFee();
      resetAmounts();
    }
  };

  /**
   * @function
   * @name handleCreateDeboursExt
   * @author Succi Iris
   * @date 2023
   * @description Création d'un débours externe
   */
  const handleCreateDeboursExt = async () => {
    const dataDeboursExt = {
      ANA_NOTES: commentDE,
      ANA_QUANTITE: Number(quantityDE),
      ANA_EMTHT: htSell,
      ANA_EMTNS: nsSell,
      ANA_EMTTVA: tvaSell,
      ANA_EPRIXUNITAIRE: Number(unitPriceSell),
      ANA_DATEREALISATION: new Date(),
      ANA_ECOUTUNITAIRE: Number(unitPriceBuy),
      ANA_ECOUTTTC: ttcBuy,
      ANA_ECOUTNS: nsBuy,
      ANA_ECOUTTVA: tvaBuy,
      PFIC_ID_DEBEXT_CL: PFIC_ID,
      ANA_ECOUTHT: htBuy,
      FIC_CLE_DEBEXT_CL: FIC_CLE,
      ABN_REF_DEST: selectedSubscriptionDE,
      FIC_REF_DEBEXT_CL: selectedCustomer,
      ANA_EMTTTC: ttcSell,
      DOS_REF: selectedFile,
      MIS_REF: selectedMissionDE,
      AOR_REF: selectedOriginDE,
      ANA_CODELIB: selectedLibelleDE,
      ANA_REDACTEUR: selectedActorDE,
      TVA_CLE: selectedTvaRateDE,
      ANA_DP: provisionRequest === "false" ? 0 : 1,
      ANA_PIECE: piece,
      ANA_LIBELLE_CPT: libelleCpt,
      AOR_PROCESSUS: selectedProcessus,
    };

    setIsLoading(true);
    try {
      setDisplayDivFee(false);
      await postNewFee(dataDeboursExt);
      resetAll();
      successFee();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
      setCreateFees(false);
      setChoiceTab("Honoraire");
      resetDeStore();
      resetFee();
      resetAmountsForDE();
    }
  };

  /**
   * @function
   * @name handleUpdateDE
   * @description Mise à jour d'un débours externe
   * @param {void}
   * @returns {void}
   */
  const handleUpdateDE = async () => {
    const updateNewData = {
      ANA_NOTES: commentDE,
      ANA_EMTHT: htSell,
      ANA_EMTTVA: tvaSell,
      ANA_EMTTTC: ttcSell,
      ANA_EMTNS: nsSell,
      ANA_QUANTITE: Number(quantityDE),
      ANA_DATEREALISATION: new Date(),
      ANA_EPRIXUNITAIRE: Number(unitPriceSell),
      ANA_ECOUTUNITAIRE: Number(unitPriceBuy),
      ANA_ECOUTTTC: ttcBuy,
      ANA_ECOUTHT: htBuy,
      ANA_ECOUTTVA: tvaBuy,
      ANA_ECOUTNS: nsBuy,
      PFIC_ID_DEBEXT_CL: PFIC_ID,
      FIC_CLE_DEBEXT_CL: FIC_CLE,
      ABN_REF_DEST: selectedSubscriptionDE,
      FIC_REF_DEBEXT_CL: selectedCustomer,
      DOS_REF: selectedFile,
      MIS_REF: selectedMissionDE,
      AOR_REF: selectedOriginDE,
      ANA_CODELIB: selectedLibelleDE,
      ANA_REDACTEUR: selectedActorDE,
      TVA_CLE: selectedTvaRateDE,
      ANA_DP: provisionRequest === "false" ? 0 : 1,
      ANA_PIECE: piece,
      ANA_LIBELLE_CPT: libelleCpt,
    };

    setIsLoading(true);

    try {
      setDisplayDivFee(false);
      const response = await updateFee(selectedIdsInvoices[0], updateNewData);
      console.log("Succès:", response);
      resetAll();
      setSelectedIdsInvoices((_prev) => []);
      successUpdateData();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
      setSelectedIdsInvoices((_prev) => []);
      setUpdateData({});
      resetFee();
      resetAmounts();
    }
  };

  return (
    <>
      {displayDivFee ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10 flex justify-start items-center`}
          style={{
            animation: animationClass,
          }}
        >
          <button
            className="h-full w-10 flex justify-center items-center"
            style={{
              animation: animationClass,
            }}
            onClick={() => setCreateFees(false)}
          >
            <img src={chevron} alt="fleche" />
          </button>
          <div className="flex flex-col justify-start items-center w-full h-full mx-4 relative">
            {modeFee === "create" ? (
              <div className="flex flex-col justify-start items-center w-full h-[80%] mx-4">
                <h2 className="font-bold text-xl text-center 3xl:py-5 py-2 w-full">
                  Création d'un élément facturable
                </h2>
                <div className="3xl:mt-10 mt-2 flex justify-start items-center w-full bg-white rounded-md 2xl:h-20 h-16 px-4">
                  <div className="w-1/2 flex justify-start items-center ">
                    <p className="mr-10">Processus:</p>
                    <select
                      name="processus"
                      id="processus"
                      className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 mr-5"
                      value={selectedProcessus}
                      onChange={(event) => {
                        setSelectedProcessus(event.target.value);
                      }}
                    >
                      <option value={""}></option>
                      {processus?.map((processus, i) => (
                        <option key={i} value={processus?.value}>
                          {processus?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-1/2 flex justify-start items-center ">
                    <p className="mr-10">Dossier:</p>
                    <Select
                      options={files}
                      isClearable
                      className="w-full h-9 z-30 ml-5"
                      isSearchable
                      value={files.find(
                        (option) => option.value === selectedFile
                      )}
                      onChange={(selectedFile: any | null) => {
                        setSelectedFile(selectedFile?.value || 0);
                      }}
                    />
                  </div>
                </div>
                {billableItem === "Honoraire" ||
                billableItem === "Debours interne" ? (
                  <CreateFeeOrDeboursInt />
                ) : null}
                {billableItem === "Debours externe" ? (
                  <CreateDeboursExt />
                ) : null}
              </div>
            ) : (
              <div className="flex flex-col justify-start items-center w-full h-[80%] mx-4">
                <h2 className="font-bold text-xl text-center py-5 w-full">
                  Modification d'un élément facturable
                </h2>
                <div className="flex justify-around items-center w-full bg-white rounded-md h-20 px-4">
                  <div className="w-full flex justify-around items-center">
                    <div className="flex justify-start items-center ">
                      <p className="mr-5">Processus : </p>
                      <p className="font-bold text-xl">
                        {updateData?.AOR_PROCESSUS &&
                        updateData.AOR_PROCESSUS.length > 0
                          ? updateData.AOR_PROCESSUS[0].text
                          : "Aucune donnée disponible"}
                      </p>
                    </div>
                    <div className="flex justify-start items-center ">
                      <p className="mr-5">Dossier : </p>
                      <p className="font-bold text-xl">
                        {updateData?.DOS_TITRE}
                      </p>
                    </div>
                  </div>
                </div>
                {billableItem === "Honoraire" ||
                billableItem === "Debours interne" ? (
                  <CreateFeeOrDeboursInt />
                ) : billableItem === "Debours externe" ? (
                  <CreateDeboursExt />
                ) : null}
              </div>
            )}
            <div className={`absolute flex bottom-2 right-0`}>
              <button
                className="w-44 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md mr-4"
                onClick={() => {
                  setChoiceTab("Honoraire");
                  setCreateFees(false);
                  setSelectedFile(0);
                  setSelectedProcessus(0);
                  setbillableItem("");
                  setUpdateData({});
                  setSelectedIdsInvoices((_prev) => []);
                  resetAmountsForDE();
                  resetAmounts();
                  resetFee();
                  resetDeStore();
                  setModeFee("");
                }}
              >
                Annuler{" "}
              </button>
              <button
                className="w-44 h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                onClick={() => {
                  modeFee === "create" &&
                  (billableItem === "Honoraire" ||
                    billableItem === "Debours interne")
                    ? handleCreateFeeOrDeboursInt()
                    : modeFee === "create" && billableItem === "Debours externe"
                    ? handleCreateDeboursExt()
                    : modeFee === "edit" &&
                      (billableItem === "Honoraire" ||
                        billableItem === "Debours interne")
                    ? handleUpdateFees()
                    : modeFee === "edit" && billableItem === "Debours externe"
                    ? handleUpdateDE()
                    : null;
                }}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateBillableItems;
