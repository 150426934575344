import { useState, useEffect } from "react";
/**
 * @hook
 * @name useUnsavedChangesWarning
 * @author Iris Succi
 * @date 2023
 * @description Un hook qui permet d'afficher une alerte si l'utilisateur tente de quitter une page avec des modifications non enregistrées.
 */

const useUnsavedChangesWarning = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [isTyping, setIsTyping] = useState(false);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (isTyping) {
      const message =
        "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?";
      event.returnValue = message;
      return message;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isTyping]);

  return [isTyping, setIsTyping];
};

export default useUnsavedChangesWarning;
