import { useSousFicheStore } from "../../../../store/Crm/SousFiche";
import { handlePhoneChange } from "../../../../utils/functionService";

/**
 * @component
 * @name RenderSousFicheGaranties
 * @author Succi Iris
 * @date 2025
 * @description RenderSousFicheGaranties est un composant qui permet de rendre une sous fiche EC
 */
const RenderSousFicheGaranties = () => {
  const { fields, data, setData } = useSousFicheStore();

  // Fonction pour générer les champs en fonction du type de champ (text, textarea, date, choice-list, phone)
  const inputType = (
    type: string,
    name: string,
    getter: any,
    setter: any,
    options?: any
  ) => {
    switch (type) {
      case "text":
        return (
          <input
            type="text"
            name={name}
            id={name}
            onChange={(e) => setter(e.target.value)}
            value={getter}
            className="border-[1px] rounded-md w-full p-2"
          />
        );
      case "textarea":
        return (
          <textarea
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full h-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          />
        );
      case "date":
        return (
          <input
            type="date"
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full p-2"
            value={getter ? new Date(getter).toISOString().split("T")[0] : ""} // Conversion au format YYYY-MM-DD
            onChange={(e) => setter(e.target.value)}
          />
        );
      case "choice-list":
        return (
          <select
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          >
            <option value="" className="text-slate-300 "></option>
            {options?.map((option: any, i: any) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "phone":
        return (
          <input
            type="tel"
            name={name}
            id={name}
            onChange={(e) => handlePhoneChange(e, setter)} // Appelle la fonction handlePhoneChange pour creer un espace
            value={getter}
            className="border-[1px] rounded-md w-full p-2"
          />
        );
      default:
        return "text";
    }
  };

  return (
    <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p className="font-bold">Nom du garant</p>
      </div>
      <div className="col-start-2 col-end-5" id="Nom du garant">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Nom du garant");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Nom du bénéficiaire</p>
      </div>
      <div className="col-start-2 col-end-3" id="Nom du bénéficiaire">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Nom du bénéficiaire"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Montant</p>
      </div>
      <div className="col-start-2 col-end-3" id="Montant">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Montant");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>
      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Date d'échéance</p>
      </div>
      <div className="col-start-2 col-end-3" id="Date d'échéance">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Date d'échéance"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Observations</p>
      </div>
      <div className="col-start-2 col-end-4" id="Observations">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Observations");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>
    </div>
  );
};

export default RenderSousFicheGaranties;
