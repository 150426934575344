import { t } from "i18next";
import Search from "../../../assets/icons/search.svg";

/**
 * @component
 * @name Research
 * @description Un composant qui permet de rechercher un dossier sur le tableau de bord de l'application. (EXEMPLE)
 * @author Iris Succi
 * @date 2025
 */
const Research = () => {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="relative w-full m-auto flex justify-center items-center">
        <input
          type="text"
          className="w-full border-2 border-bc-orange rounded-md h-10 p-2 px-8 ml-2"
          placeholder={t("Rechercher un dossier")}
        />
        <img src={Search} alt="search" className="absolute left-3 top-2.5" />
      </div>
    </div>
  );
};

export default Research;
