import Charts1 from "./Charts1";
import Charts2 from "./Charts2";
import NextAppointment from "./NextAppointment";
import NextAudiance from "./NextAudiance";
import QuickWarning1 from "./QuickWarning1";
import QuickWarning2 from "./QuickWarning2";
import Research from "./Research";
import TasksToBeDone from "./TasksToBeDone";

/**
 * @component
 * @name Dashboard
 * @description Un composant qui affiche le tableau de bord de l'application en suivant la maquette données, chaque partie du tableau de bord est un composant à part entière.
 * @author Iris Succi
 * @date 2025
 */
const Dashboard = () => {
  return (
    <div className="w-11/12 flex flex-col justify-start items-center">
      <div className="w-full h-20 flex justify-start items-center relative">
        <div className="w-full border-2 border-bc-orange"></div>
        <div className="bg-black h-5 w-56 absolute left-1/2 -translate-x-1/2 flex justify-center items-center rounded-md">
          <p className="text-white">DASHBOARD</p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-20 w-full h-full">
        <NextAudiance />
        <NextAppointment />
        <TasksToBeDone />
      </div>
      <div className="grid grid-cols-3 gap-20 w-full h-full 3xl:mt-36 mt-10">
        <Research />
        <QuickWarning1 />
        <QuickWarning2 />
      </div>
      <div className="w-full h-20 flex justify-start items-center relative">
        <div className="w-full border-2 border-bc-orange"></div>
        <div className="bg-black h-5 w-56 absolute left-1/2 -translate-x-1/2 flex justify-center items-center rounded-md">
          <p className="text-white">ANALYSE DE L'ACTIVITE</p>
        </div>
      </div>
      <p className="w-full text-center mb-10 text-md">
        FACTURATION PAR RESPONSABLE
      </p>
      <div className="flex justify-start items-start w-10/12 m-auto h-full">
        <Charts1 />
        <Charts2 />
      </div>
    </div>
  );
};

export default Dashboard;
