import axios from "axios";

const backendUrl = import.meta.env.VITE_BACKEND_URL;

/**
 * @function axiosWithoutToken
 * @description Creer et configure une instance Axios pour effectuer des requêtes sans jeton d'authentification.
 * @since 2023-06-30
 * @author Succi Iris
 */
const axiosWithoutToken = () => {
  const instance = axios.create({
    baseURL: backendUrl,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.withCredentials = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

/**
 * @function axiosWithToken
 * @description Créer et configure une instance Axios pour effectuer des requêtes avec un jeton d'authentification.
 * @since 2023-06-30
 * @author Succi Iris
 */
const axiosWithToken = () => {
  const instance = axios.create({
    baseURL: backendUrl,
  });

    instance.interceptors.request.use(
      (config) => {
        config.headers["Content-Type"] = "application/json";
        config.headers["Access-Control-Allow-Credentials"] = true;
        config.withCredentials = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

  return instance;
};

/**
 * @function axiosWithTokenForBlob
 * @description Crée et configure une instance Axios pour effectuer des requêtes avec un jeton d'authentification et un type de réponse blob.
 * @since 2023-06-30
 * @author Succi Iris
 */
const axiosWithTokenForBlob = () => {
  const instance = axios.create({
    baseURL: backendUrl,
    responseType: "blob",
  });
  
  // ... Configuration de l'intercepteur
  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.withCredentials = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};

/**
 * @function axiosWithTokenForzip
 * @description Crée et configure une instance Axios pour effectuer des requêtes avec un jeton d'authentification et un type de réponse zip.
 * @since 2023-06-30
 * @author Succi Iris
 */
const axiosWithTokenForzip = () => {
  const instance = axios.create({
    baseURL: backendUrl,
  });
  
  // ... Configuration de l'intercepteur
  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/zip";
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.withCredentials = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
}

export { axiosWithToken, axiosWithoutToken, axiosWithTokenForBlob, axiosWithTokenForzip };
